import React from 'react'
import profile from '../img/John-Dilworth-Profile.jpg'

const Author = () => (
  <section className="author">
    <img src={profile} className="profile-photo" alt="John Dilworth"/>
    <p> This was written by John Dilworth. John lives in Ogden, Utah with his wife and two children.</p>
  </section>
)

export default Author
