import Layout from "../components/layout"
import {graphql} from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import Content, { HTMLContent } from '../components/Content'
import Author from '../components/Author'
import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import LeftArrow from  '../img/left-arrow.svg'
import RightArrow from  '../img/right-arrow.svg'
import "animate.css/animate.css";
import ScrollAnimation from 'react-animate-on-scroll';

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  coverquote,
  date,
  timeToRead,
  helmet,
  path,
  thumb,
  prev,
  next,
  templateKey,
}) => {
  const PostContent = contentComponent || Content;
  const hasNext = next && (next.frontmatter.templateKey === templateKey);

  const hasPrev = prev && (prev.frontmatter.templateKey === templateKey);

  return (
    <Layout>
    <PageTransition>
    <BodyClassName className="article">

    <div className="pageWrapper">

        {hasPrev &&
          prev &&
          <Link to={prev.fields.slug} className="nextButton">
            <img className="nextArrow" src={RightArrow} alt="next"/>
          </Link>
        }

        {hasNext &&
          next &&
          <Link to={next.fields.slug} className="prevButton">
            <img className="prevArrow" src={LeftArrow} alt="previous"/>
          </Link>
        }
    <Helmet>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@johndilworth" />
      <meta name="twitter:creator" content="@johndilworth" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={"https://johndilworth.com"+thumb} />
    </Helmet>
    <article className="article">
    <div className="content">
      <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
        <header className="articleHeader">
          <h1>
            {title}
          </h1>
          <p className="no-margin">{description}</p>
          <p className="no-margin"><small>Published {date} &middot; {timeToRead} min read time</small></p>
        </header>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
          <blockquote className="intro">
            {coverquote}
          </blockquote>
        </ScrollAnimation>

        <ScrollAnimation animateIn="fadeInUp2" duration={.5}>
            <PostContent content={content} className='article-content'/>
        </ScrollAnimation>
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <ul className="taglist">
                  {tags.map(tag => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
      </div>
      <ScrollAnimation animateIn="fadeIn">
      <Author/>
      </ScrollAnimation>
    </article>
    </div>
    </BodyClassName>
    </PageTransition>
    </Layout>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  templateKey: PropTypes.string,
  helmet: PropTypes.instanceOf(Helmet),
  prev: PropTypes.object,
  next: PropTypes.object,
}

const BlogPost = ({ data, pageContext }) => {
  const { markdownRemark: post} = data;
  const { next, prev } = pageContext;

  return (
    <BlogPostTemplate

      content={post.html}
      contentComponent={HTMLContent}
      description={post.frontmatter.description}
      tags={post.frontmatter.tags}
      title={post.frontmatter.title}
      date={post.frontmatter.date}
      coverquote={post.frontmatter.coverquote}
      image={post.frontmatter.image}
      timeToRead={post.timeToRead}
      templateKey={post.frontmatter.templateKey}
      path={post.frontmatter.path}
      prev= {prev}
      next= {next}
      thumb={post.frontmatter.thumb.childImageSharp.fluid.src}
    />
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
  pageContext: PropTypes.shape({
    prev: PropTypes.object,
    next: PropTypes.object,
  })
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site{
      siteMetadata{
        title
        description
        siteUrl
      }
    }
    markdownRemark(id: {eq: $id} ) {
      id
      html
      timeToRead
      frontmatter {
        templateKey
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        coverquote
        path
        thumb{
          childImageSharp{
            fluid(maxWidth: 500){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
